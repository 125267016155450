<template>
  <PageContainer>
    <div class="layout">
      <resize-wrap class="flex-1">
        <BaseTable
          id="padInfo"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :seq-config="{
            startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
          }"
          :align="allAlign"
          :data="tableData"
        >
          <template #buttons>
            <el-button
              type="primary"
              v-auth="'平板料罐信息_新增'"
              icon="el-icon-circle-plus-outline"
              @click="
                dialogEdit = true;
                addRow();
              "
            >新增
            </el-button>
          </template>
          <vxe-table-column type="seq" title="序号" width="60" />
          <!--          <vxe-table-column field="_accoId" title="创建账户" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="_compId" title="创建企业" show-overflow="title" min-width="80" show-header-overflow="title"/>-->
          <vxe-table-column field="imei" title="平板串号" show-overflow="title" min-width="120" show-header-overflow="title"/>
          <vxe-table-column field="deviceType" title="设备类型" min-width="100" show-overflow="title" show-header-overflow="title"
                            :formatter="
                              ({cellValue, row, column }) => {
                                return cellValue == 0?'未知':'料仓平板';
                              }"/>
          <vxe-table-column field="index" title="料罐序号" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="name" title="料罐名称" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="iotClientId" title="iot客户端流水号" show-overflow="title" min-width="130" show-header-overflow="title"/>
          <vxe-table-column field="machineID" title="生产线编号" show-overflow="title" min-width="110" show-header-overflow="title"/>
          <vxe-table-column field="maxWeight" title="最大容量(吨)" show-overflow="title" min-width="110" show-header-overflow="title" :formatter="
            ({cellValue, row, column }) => {
              return cellValue ?Math.round(cellValue/1000):0;
            }"/>
          <vxe-table-column field="weight" title="当前重量(吨)" show-overflow="title" min-width="100" show-header-overflow="title" :formatter="
            ({cellValue, row, column }) => {
              return cellValue ?Math.round(cellValue/1000):0;
            }"/>
          <vxe-table-column field="doorState" title="打料口状态" show-overflow="title" min-width="100" show-header-overflow="title"
                            :formatter="
                              ({cellValue, row, column }) => {
                                return cellValue == 0?'关闭':'打开';
                              }"/>/>
          <vxe-table-column field="hexString" title="原始数据" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="address" title="单个料罐控制板地址" show-overflow="title" min-width="150" show-header-overflow="title"/>
          <vxe-table-column field="appSecret" title="appKey密钥对应app密码" show-overflow="title" min-width="150" show-header-overflow="title"/>
          <vxe-table-column field="customName" title="料罐自定义名称" show-overflow="title" min-width="110" show-header-overflow="title"/>
          <vxe-table-column field="logId" title="日志流水号(主记录)" show-overflow="title" min-width="150" show-header-overflow="title"/>
          <vxe-table-column field="opertime" title="操作时间" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="regtime" title="新增时间" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="remark" title="备注" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column title="操作" min-width="200">
            <template v-slot="{ row }">
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editRow(row)" v-auth="'平板料罐信息_修改'" title="修改"/>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)" v-auth="'平板料罐信息_删除'" title="删除"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="[
          'Sizes',
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Total',
        ]"
        @page-change="handlePageChange"
      />
      <el-dialog
        :title="dialogEditTitle"
        :visible.sync="dialogEdit"
        :v-if="dialogEdit"
        :append-to-body="true"
        width="850px"
        v-dialogDrag
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          :inline="true"
          ref="addForm"
          size="mini"
          label-position="right"
          label-width="160px"
          :model="padEditModel"
          style="margin-top: 20px"
        >
          <el-form-item label="平板串号:" >
            <el-input v-model="padEditModel.imei" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="iot客户端流水号:">
            <el-input v-model="padEditModel.iotClientId" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item label="料罐序号:">
            <el-input v-model="padEditModel.index" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="料罐名称:">
            <el-input v-model="padEditModel.name" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="料罐自定义名称:">
            <el-input v-model="padEditModel.customName" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="生产线编号:">
            <el-input v-model="padEditModel.machineID" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="单个料罐控制版地址:">
            <el-input v-model="padEditModel.address" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="appKey密钥对应app密码:">
            <el-input v-model="padEditModel.appSecret" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="最大容量:">
            <el-input v-model="padEditModel.maxWeight" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="当前重量:">
            <el-input v-model="padEditModel.weight" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="是否为日志记录:">
            <el-select v-model="padEditModel.isLog" filterable clearable style="width: 200px">
              <el-option label="否" value="0"></el-option>
              <el-option label="是" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备类型:">
            <el-select v-model="padEditModel.deviceType" filterable clearable style="width: 200px">
              <el-option label="未知" value="0"></el-option>
              <el-option label="料仓平板" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="打料口状态:">
            <el-select v-model="padEditModel.doorState" filterable clearable style="width: 200px">
              <el-option label="关闭" value="0"></el-option>
              <el-option label="打开" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="padEditModel.remark" style="width: 180px">
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitBusEdit()" size="mini"
          >保存</el-button
          >
        </div>
      </el-dialog>
    </div>
  </PageContainer>
</template>

<script>
import { getComps } from '@/apis/common'
import {
  gianListPadTankInfo,
  setPadTankInfo,
  deletePadTankInfo
} from '@/apis/stock/padInfo'
import XEUtils from 'xe-utils'

export default {
  name: 'pad-info',
  data () {
    return {
      padEditModel: {
        address: '',
        appSecret: '',
        customName: '',
        deviceType: 0,
        doorState: 0,
        hexString: '',
        imei: '',
        index: 0,
        iotClientId: '',
        isLog: '1',
        logId: 0,
        machineID: '',
        maxWeight: 0,
        name: '',
        opertime: '',
        regtime: '',
        remark: '',
        status: true,
        stockID: '',
        tabletTankId: '',
        weight: 0
      },
      rules: {
        compId: [
          { required: true, message: '企业名称为必填选项', trigger: 'change' }
        ]
      },
      dialogEditTitle: '新增平板料罐信息',
      dialogEdit: false,
      searchModel: {
      },
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: [
          'Sizes',
          'PrevJump',
          'PrevPage',
          'Number',
          'NextPage',
          'NextJump',
          'FullJump',
          'Total'
        ],
        perfect: true
      },
      companyList: [],
      compListOptions: {},
      qrCodeContent: '',
      codeVisible: false
    }
  },
  methods: {
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    refreshData () {
      this.loading = true
      gianListPadTankInfo({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      })
        .then((res) => {
          this.loading = false
          if (res.code == 200) {
            const data = res.data
            this.tableData = data.rows
            this.tablePage.total = data.totalSize
          } else {
            this.tableData = []
            this.tablePage.total = 0
          }
        })
        .catch((error) => {
          console.log(error)
          //  this.logining = false;
        })
    },
    addRow () {
      this.dialogEditTitle = '新增平板料罐信息'
      this.padEditModel = {
        address: '',
        appSecret: '',
        customName: '',
        deviceType: 0,
        doorState: 0,
        hexString: '',
        imei: '',
        index: 0,
        iotClientId: '',
        isLog: '1',
        logId: 0,
        machineID: '',
        maxWeight: 0,
        name: '',
        opertime: '',
        regtime: '',
        remark: '',
        status: true,
        stockID: '',
        tabletTankId: '',
        weight: 0
      }
    },
    editRow (row) {
      this.dialogEditTitle = '修改平板-料罐信息'
      const rowData = {}
      XEUtils.objectEach(this.padEditModel, (item, key) => {
        rowData[key] = row[key] ? row[key].toString() : ''
      })
      this.padEditModel = Object.assign({}, rowData)
      this.dialogEdit = true
    },
    deleteRow (row) {
      this.$confirm('确定删除该平板料罐信息?', '删除平板-料罐信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        deletePadTankInfo({
          ids: [row.tabletTankId]
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    handleItemChange (val) {},
    onSubmitBusEdit () {
      setPadTankInfo({ ...this.padEditModel }).then((res) => {
        if (res.code === 200) {
          if (this.padEditModel.tabletTankId != '') {
            this.$message.success('修改成功！')
          } else {
            this.$message.success('添加成功！')
          }
          this.dialogEdit = false
          this.refreshData()
        } else {
          this.$message.error(res.msg)
        }
      })
    }

  },
  mounted () {
    getComps({})
      .then((res) => {
        if (res.code == 200) {
          this.companyList = res.data.rows
          const temp = {}
          this.companyList.forEach((item) => {
            temp[item.compId] = item.compName
          })
          this.compListOptions = temp
        }
        this.refreshData()
      })
      .catch((res) => {
        console.log(res)
      })
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}
</style>
